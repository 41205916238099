import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to,from,savedPosition){
      if(to.hash){
          return {
            selector:to.hash
          }
      }
    },
  routes:routes
})

export default router
