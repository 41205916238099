<template>
    <nav>
        <div class="logo"><img src="../assets/images/logo.png?v=1" alt="logo"></div>
        <div class="nav">
            <ul>
                <li data-menuanchors="index">
                    <a href="#index">首页</a>
                </li>
                <li data-menuanchors="type">
                    <a href="#type">优势</a>
                </li>
                <li data-menuanchors="form">
                    <a href="#form">广告形式</a>
                </li>
                <li data-menuanchors="partner">
                    <a href="#partner">合作</a>
                </li>
                <li data-menuanchors="footer">
                    <a href="#footer">联系我们</a>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
export default {
    name: 'TopNav'
}
</script>

<style lang="scss" scoped>
// 移动端
@media only screen and (max-width: 991px) {
    .logo img {
        width: 1.34rem;
        height: 0.38rem;
    }
}

// PC端
@media only screen and (min-width:992px) {
    .logo img {
        width: 168px;
        height: 47px;
    }
}
</style>
