<template>
    <div id="fullpage">
        <div id="swiper-tips" class="animate__animated animate__shakeY animate__infinite">
            <p>滑动查看</p>
            <img src="../assets/images/mobile/btn.png" alt="swiper-down">
        </div>

        <div class="banner section">
            <TopNav/>
            <div class="container">
                <img src="../assets/images/slogan.png" alt="slogan" class="slogan">
                <div class="login-wrap">
                    <div>
                        <div class="form-item"><input type="text" placeholder="请输入登录手机号"></div>
                        <div class="form-item"><input type="password" placeholder="请输入密码"></div>
                        <div class="form-item captcha">
                            <input type="text" placeholder="请输入验证码">
                            <div><img src="../assets/images/captcha.jpg" alt="captcha"></div>
                        </div>
                        <div class="form-item remember">
                            <input type="radio" id="remember-me" name="rememberMe" checked>
                            <label for="remember-me">记住密码</label>
                        </div>
                    </div>

                    <div class="login-btn" @click="handleLogin()">登录</div>
                    <router-link to="/" class="register-link">没有账号，点击注册</router-link>
                </div>
            </div>
        </div>

        <section class="adv section">
            <div class="container">
                <div>
                    <h2>ADVERTISEMENT TYPE</h2>
                    <h1>广告类型</h1>
                </div>

                <div class="card-wrap" v-if="!isMobile">
                    <div class="card-item"><img src="../assets/images/1.png" alt="1"></div>
                    <div class="card-item"><img src="../assets/images/2.png" alt="2"></div>
                    <div class="card-item"><img src="../assets/images/3.png" alt="3"></div>
                    <div class="card-item"><img src="../assets/images/4.png" alt="4"></div>
                </div>
                <div class="card-wrap" v-else>
                    <div class="card-item"><img src="../assets/images/mobile/1.jpg" alt="1"></div>
                    <div class="card-item"><img src="../assets/images/mobile/2.jpg" alt="2"></div>
                    <div class="card-item"><img src="../assets/images/mobile/3.jpg" alt="3"></div>
                    <div class="card-item"><img src="../assets/images/mobile/4.jpg" alt="4"></div>
                </div>
            </div>
        </section>

        <section class="choose-us section">
            <div class="container">
                <div>
                    <h2>WHY CHOOSE US</h2>
                    <h1>为什么选择我们</h1>
                </div>

                <div class="card-wrap" v-if="!isMobile">
                    <div class="card-item">
                        <div class="image"><img src="../assets/images/pic_1.png" alt="1"></div>
                        <h3>海量媒体资源</h3>
                        <div>
                            <p>以独家资源及各大媒体矩阵为核心</p>
                            <p>针对不同没提实现多维把标签、大</p>
                            <p>而专、小而精的呈现方式</p>
                        </div>
                    </div>
                    <div class="card-item">
                        <div class="image"><img src="../assets/images/pic_2.png" alt="2"></div>
                        <h3>精准投放</h3>
                        <div>
                            <p>多维度用户标签及专业的用户群画</p>
                            <p>像，提供一站式服务闭环，帮助各</p>
                            <p>类广告主快速锁定目标用户实现效</p>
                            <p>果最大化</p>
                        </div>
                    </div>
                    <div class="card-item">
                        <div class="image"><img src="../assets/images/pic_3.png" alt="3"></div>
                        <h3>互利共赢</h3>
                        <div>
                            <p>有效整合资源，为合作伙伴带来利</p>
                            <p>益最大化</p>
                        </div>
                    </div>
                </div>
                <div class="card-wrap" v-else>
                    <div class="card-item">
                        <div class="image image-1">
                            <h3>海量媒体资源</h3>
                            <div>
                                <p>以独家资源及各大媒体矩阵为核心针对不同没提实</p>
                                <p>现多维把标签、大而专、小而精的呈现方式</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-item">
                        <div class="image image-2">
                            <h3>精准投放</h3>
                            <div>
                                <p>多维度用户标签及专业的用户群画像，提供一站式</p>
                                <p>服务闭环，帮助各类广告主快速锁定目标用户实现</p>
                                <p>效果最大化</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-item">
                        <div class="image image-3">
                            <h3>互利共赢</h3>
                            <div>
                                <p>有效整合资源，为合作伙伴带来利益最大化</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="adv-form section">
            <div class="container">
                <div>
                    <h2>ADVERTISEMENT FORM</h2>
                    <h1>广告形式</h1>
                </div>

                <div class="swiper">
                    <div class="swiper-wrapper" v-if="!isMobile">
                        <div class="swiper-slide">
                            <div class="phone-wrap">
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/11.png" alt="11"></div>
                                    <p>激励视频</p>
                                </div>
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/12.png" alt="12"></div>
                                    <p>视频广告</p>
                                </div>
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/13.png" alt="13"></div>
                                    <p>开屏广告</p>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone-wrap">
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/14.png" alt="14"></div>
                                    <p>贴片广告</p>
                                </div>
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/15.png" alt="15"></div>
                                    <p>横幅广告</p>
                                </div>
                                <div class="phone">
                                    <div class="image"><img src="../assets/images/16.png" alt="16"></div>
                                    <p>插屏广告</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-wrapper" v-else>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/11@2x.png" alt="11"></div>
                                <p>激励视频</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/12@2X.png" alt="12"></div>
                                <p>视频广告</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/13@2x.png" alt="13"></div>
                                <p>开屏广告</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/14@2x.png" alt="14"></div>
                                <p>贴片广告</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/15@2x.png" alt="15"></div>
                                <p>横幅广告</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="phone">
                                <div class="image"><img src="../assets/images/mobile/16@2x.png" alt="16"></div>
                                <p>插屏广告</p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div>
        </section>

        <section class="partner section">
            <div class="container">
                <div>
                    <h2>PARTNER</h2>
                    <h1>合作伙伴</h1>
                </div>
                <div v-if="!isMobile">
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_1.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_2.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_3.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_4.png" alt="logo"></div>
                    </div>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_5.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_6.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_7.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_8.png" alt="logo"></div>
                    </div>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_9.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_10.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_11.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_12.png" alt="logo"></div>
                    </div>
                </div>
                <div v-else>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_1.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_2.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_3.png" alt="logo"></div>
                    </div>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_4.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_5.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_6.png" alt="logo"></div>
                    </div>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_7.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_8.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_9.png" alt="logo"></div>
                    </div>
                    <div class="partner-wrap">
                        <div class="partner-logo"><img src="../assets/images/logo_10.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_11.png" alt="logo"></div>
                        <div class="partner-logo"><img src="../assets/images/logo_12.png" alt="logo"></div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="section">
            <div class="footer-logo hidden-sm"></div>
            <div class="qrcode"><img src="../assets/images/mobile/qrcode@2x.png" alt="qrcode"></div>
            <div class="company">
                <p>北京云崇科技有限公司</p>
                <p>电话：13810180728</p>
                <p>地址：北京市东城区王府国际中心5层</p>
                <p><a style="color: #fff;" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备20029375号-2</a></p>
            </div>
        </footer>
    </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import fullpage from 'fullpage.js'
import 'fullpage.js/dist/fullpage.css'
import Swiper from 'swiper'

const { body } = document
const WIDTH = 992

export default {
    name: 'HomeView',
    components: {
        TopNav,
    },
    data() {
        return {
            // 是否是移动端
            isMobile: false,
            swiper: null,
        }
    },
    mounted() {
        const rect = body.getBoundingClientRect()
        this.isMobile = rect.width - 1 < WIDTH

        new fullpage('#fullpage', {
            navigation: true,
            navigationPosition: 'left',
            controlArrows: false,
            anchors: ['index', 'type', 'choose', 'form', 'partner', 'footer'],
            fixedElements: '#swiper-tips',
            paddingBottom: 20,
        })

        this.$nextTick(() => {
            this.swiper = new Swiper('.swiper', {
                loop: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })
        })

        window.onresize = () => {
            this.isMobile = body.getBoundingClientRect().width - 1 < WIDTH

            this.$nextTick(() => {
                this.swiper && this.swiper.destroy()
                this.$nextTick(() => {
                    this.swiper = new Swiper('.swiper', {
                        loop: true,
                        autoplay: {
                            delay: 2500,
                            disableOnInteraction: false,
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable :true,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    })
                })
            })
        }
    },
    methods: {
        handleLogin: function () {
            alert('login')
        }
    }
}
</script>

<style lang="scss" scoped>
// 移动端
@media only screen and (max-width: 991px) {

    .hidden-sm {
        display: none !important;
    }

    #swiper-tips {
        position: fixed;
        width: 100%;
        text-align: center;
        z-index: 9999;
        bottom: 0.24rem;
        --animate-duration: 5s;

        p {
            height: 0.40rem;
            font-size: 0.28rem;
            color: #969CAD;
            letter-spacing: 0;
        }

        img {
            width: 0.24rem;
            height: 0.24rem;
        }
    }

    .banner {
        background: url("../assets/images/mobile/bg.jpg");
        background-size: 100% 100%;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;

            .slogan {
                display: block;
                margin: 0 auto;
                width: 4.41rem;
            }

            .login-wrap {
                margin-top: 0.56rem;
                width: 5.20rem;
                height: 6.02rem;
                padding: 0.40rem;
                background: #FFFFFF;
                border-radius: 0.08rem;

                .form-item {
                    width: 4.40rem;
                    height: 0.72rem;
                    margin-bottom: 0.24rem;

                    &:last-child {
                        margin-bottom: 0;
                        height: 0.33rem;
                    }

                    input[type="text"], input[type="password"] {
                        width: 100%;
                        height: 0.72rem;
                        border: 0.01rem solid #EFF1F7;
                        background: #EFF1F7;
                        border-radius: 0.06rem;

                        font-size: 0.28rem;
                        padding: 0.16rem;
                        outline: none;
                        color: #333333;

                        &:focus {
                            border: 0.01rem solid #296DF8;
                        }
                    }

                    &.captcha {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 0.24rem;

                        input {
                            width: 2.68rem !important;
                        }

                        img {
                            width: 1.48rem;
                            height: 0.72rem;
                        }
                    }

                    input::-webkit-input-placeholder {
                        color: #9297A5;
                    }

                    :-moz-placeholder {
                        color: #9297A5;
                    }

                    ::-moz-placeholder {
                        color: #9297A5;
                    }

                    input:-ms-input-placeholder {
                        color: #9297A5;
                    }

                    input::-ms-input-placeholder {
                        color: #9297A5;
                    }

                    &.remember {
                        display: flex;
                        align-items: center;

                        #remember-me {
                            width: 0.24rem;
                            height: 0.24rem;
                        }

                        #remember-me + label {
                            margin-left: 0.08rem;
                            font-size: 0.24rem;
                            line-height: 0.33rem;
                        }
                    }
                }
            }

            .login-btn {
                width: 4.40rem;
                height: 0.72rem;
                line-height: 0.72rem;
                text-align: center;
                background: #296DF8;
                border-radius: 0.06rem;
                margin-top: 0.50rem;

                font-size: 0.28rem;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;

                cursor: pointer;
            }

            .register-link {
                display: block;
                text-align: center;
                font-size: 0.24rem;
                color: #016BF5;
                font-weight: 400;
                text-decoration: none;
                margin-top: 0.16rem;
            }
        }
    }

    .adv {
        background: #F8F9FB;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                font-size: 0.36rem;
                height: 0.41rem;
                line-height: 0.41rem;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
            }

            h1 {
                margin-top: 0.06rem;
                height: 0.50rem;
                font-size: 0.36rem;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .card-wrap {
                margin: 0.80rem 0.80rem 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .card-item {
                    width: 2.70rem;
                    height: 3.276rem;
                    background: #173358;
                    border-radius: 0.036rem;
                    margin-bottom: 0.604rem;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .choose-us {
        background: #FFFFFF;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        h2 {
            font-size: 0.36rem;
            height: 0.41rem;
            line-height: 0.41rem;
            color: #E0E3E7;
            text-align: center;
            font-weight: 700;
        }

        h1 {
            margin-top: 0.06rem;
            height: 0.50rem;
            font-size: 0.36rem;
            color: #333333;
            text-align: center;
            font-weight: 600;
        }

        .card-wrap {
            margin: 0.80rem 0.50rem 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .card-item {
                width: 6.50rem;
                height: 2.64rem;
                border-radius: 0.04rem;
                margin-bottom: 0.40rem;

                .image {
                    width: 6.50rem;
                    height: 2.64rem;
                    padding: 0 0.61rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.image-1 {
                        background: url(../assets/images/mobile/pic_1.jpg);
                        background-size: 100%;
                    }

                    &.image-2 {
                        background: url(../assets/images/mobile/pic_2.jpg);
                        background-size: 100%;
                    }

                    &.image-3 {
                        background: url(../assets/images/mobile/pic_3.jpg);
                        background-size: 100%;
                    }

                }

                h3 {
                    height: 0.42rem;
                    line-height: 0.42rem;
                    text-align: center;
                    margin: 0 auto;
                    color: #FFFFFF;
                    position: relative;

                    font-size: 0.30rem;
                    letter-spacing: 0.0333rem;
                    font-weight: 500;
                }

                p {
                    height: 0.33rem;
                    margin: 0 auto;
                    font-size: 0.24rem;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: justify;
                    font-weight: 400;
                    margin-top: 0.20rem;

                    &:not(:first-child) {
                        margin-top: 0.06rem;
                    }
                }
            }
        }
    }

    .adv-form {
        background: #F8F9FB;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                font-size: 0.36rem;
                height: 0.41rem;
                line-height: 0.41rem;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
            }

            h1 {
                margin-top: 0.06rem;
                height: 0.50rem;
                font-size: 0.36rem;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .swiper {
                margin-top: 0.8rem;
                overflow: hidden;
            }

            .phone {
                width: 3.24rem;
                margin: 0 auto;

                .image {
                    width: 3.24rem;
                    height: 6.38rem;

                    img {
                        width: 3.24rem;
                        height: 6.38rem;
                    }
                }

                p {
                    margin-top: 0.36rem;
                    font-size: 0.28rem;
                    color: #333333;
                    text-align: center;
                    font-weight: 500;
                    height: 0.40rem;
                    line-height: 0.40rem;
                }
            }
        }
    }

    .partner {
        justify-content: center;

        .container {
            h2 {
                font-size: 0.36rem;
                height: 0.41rem;
                line-height: 0.41rem;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
            }

            h1 {
                margin-top: 0.06rem;
                height: 0.50rem;
                font-size: 0.36rem;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .partner-wrap {
                margin: 0.80rem 0.70rem 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .partner-logo {
                    width: 1.63rem;
                    height: 0.56rem;

                    img {
                        width: 1.63rem;
                        height: 0.56rem;
                    }
                }

                &:first-child {
                    margin-top: 0.80rem;
                }
            }
        }
    }

    footer {
        height: 2.00rem;
        background: url("../assets/images/mobile/bg_2.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        .qrcode {
            width: 1.19rem;
            height: 1.19rem;
            background-color: #fff;
            margin: 0.4rem 0.2455rem 0 0.40rem;

            img {
                width: 1.19rem;
                height: 1.19rem;
            }
        }

        .company {
            margin: 0.3rem 0 0 0;

            p {
                height: 0.30rem;
                font-size: 0.22rem;
                color: #FEFFFF;
                letter-spacing: 0;
                font-weight: 400;

                &:not(:first-child) {
                    margin-top: 0.08rem;
                }
            }
        }
    }

    ::v-deep .swiper-button-next:after, ::v-deep .swiper-button-prev:after {
        font-size: 0.80rem;
        color: #dfe2e8;
    }

    ::v-deep .swiper-button-prev {
        margin-top: 0.12rem;
        left: 0.80rem;
    }

    ::v-deep .swiper-button-next {
        margin-top: 0.12rem;
        right: 0.80rem;
    }
}

// PC端
@media only screen and (min-width:992px) {
    #swiper-tips {
        position: fixed;
        width: 100%;
        text-align: center;
        z-index: 9999;
        bottom: 24px;
        --animate-duration: 5s;

        p {
            height: 22px;
            font-size: 16px;
            color: #969CAD;
            letter-spacing: 0;
        }

        img {
            width: 18px;
            height: 18px;
        }
    }

    .banner {
        background: url("../assets/images/bg.png");
        background-size: 100% 100%;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100vh;

            .slogan {
                display: block;
                margin: 0 auto;
                width: 472px; // 24.583333vw;
            }

            .login-wrap {
                margin-top: 5.16129vh;
                margin-bottom: 10vh;
                width: 496px;
                height: 490px; // 52.688172vh;
                padding: 48px; // 2.500000vw;
                background: #FFFFFF;
                border-radius: 8px;

                .form-item {
                    width: 400px; // 20.833333vw;
                    height: 48px; // 5.161290vh;
                    margin-bottom: 30px; // 3.225806vh;

                    &:last-child {
                        margin-bottom: 0;
                        height: 20px; // 2.150538vh;
                    }

                    input[type="text"], input[type="password"] {
                        width: 100%;
                        height: 48px; // 5.161290vh;
                        border: 1px solid #EFF1F7;
                        background: #EFF1F7;
                        border-radius: 4px;

                        font-size: 16px; // 0.833333vw;
                        padding: 13px 16px; // 1.397849vh 0.833333vw;
                        outline: none;
                        color: #333333;

                        &:focus {
                            border: 1px solid #296DF8;
                        }
                    }

                    &.captcha {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 24px; // 2.580645vh;

                        input {
                            width: 240px !important; // 12.500000vw !important;
                        }

                        img {
                            width: 141px; // 7.343750vw;
                            height: 48px; // 5.161290vh;
                        }
                    }

                    input::-webkit-input-placeholder {
                        color: #9297A5;
                    }

                    :-moz-placeholder {
                        color: #9297A5;
                    }

                    ::-moz-placeholder {
                        color: #9297A5;
                    }

                    input:-ms-input-placeholder {
                        color: #9297A5;
                    }

                    input::-ms-input-placeholder {
                        color: #9297A5;
                    }

                    &.remember {
                        display: flex;
                        align-items: center;

                        #remember-me {
                            width: 20px; // 1.041667vw;
                            height: 20px; // 2.150538vh;
                        }

                        #remember-me + label {
                            margin-left: 8px;
                            font-size: 14px; // 0.729167vw;
                            line-height: 14px; // 1.505376vh;
                        }
                    }
                }
            }

            .login-btn {
                width: 400px; // 20.833333vw;
                height: 54px; // 5.806452vh;
                line-height: 54px; // 5.806452vh;
                text-align: center;
                background: #296DF8;
                border-radius: 4px;
                margin-top: 56px; // 6.0215vh;

                font-size: 16px; // 0.833333vw;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;

                cursor: pointer;
            }

            .register-link {
                display: block;
                text-align: center;
                font-size: 14px; // 0.729167vw;
                color: #016BF5;
                font-weight: 400;
                text-decoration: none;
                margin-top: 16px; // 1.720430vh;
            }
        }
    }

    .adv {
        background: #F8F9FB;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100vh;
            padding-top: 21.612903vh; // 201px

            h2 {
                font-size: 30px; // 1.250000vw;
                height: 33px; // 2.903226vh;
                line-height: 33px; // 2.903226vh;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
            }

            h1 {
                margin-top: 8px; // 0.537634vh;
                height: 42px; // 3.548387vh;
                font-size: 30px; // 1.250000vw;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .card-wrap {
                margin: 8.602151vh 0 21.72043vh; // 0 14.375vw 21.72043vh;
                display: flex;
                justify-content: space-between;

                .card-item {
                    width: 300px; // 15.625vw;
                    height: 364px; // 39.13978vh;
                    background: #173358;
                    border-radius: 4px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .choose-us {
        background: #FFFFFF;

        .container {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        //padding-top: 17.634409vh; // 164px
        //justify-content: flex-start;

        h2 {
            font-size: 30px; // 1.562500vw
            height: 33px; // 3.548387vh
            line-height: 33px; // 3.548387vh
            color: #E0E3E7;
            text-align: center;
            font-weight: 700;
        }

        h1 {
            margin-top: 8px; // 0.860215vh
            height: 42px; // 4.516129vh
            font-size: 30px; // 1.562500vw
            color: #333333;
            text-align: center;
            font-weight: 600;
        }

        .card-wrap {
            margin: 8.602151vh 0 15.376344vh; // 80px 0px 143px;
            display: flex;
            justify-content: space-between;

            .card-item {
                width: 396px; // 20.625000vw
                height: 459px; // 49.354839vh

                .image {
                    width: 396px; // 20.625000vw
                    height: 264px; // 28.387097vh

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                h3 {
                    width: 264px; // 13.750000vw
                    height: 58px; // 6.236559vh
                    line-height: 58px; // 6.236559vh
                    text-align: center;
                    background: #244674;
                    margin: 0 auto;
                    color: #FFFFFF;
                    position: relative;
                    top: -29px; // -3.118280vh

                    font-size: 22px; // 1.145833vw
                    letter-spacing: 2.44px; // 0.127083vw
                    font-weight: 500;
                }

                p {
                    width: 256px; // 13.333333vw
                    margin: 0 auto;
                    font-size: 16px; // 0.833333vw
                    color: #333333;
                    letter-spacing: 0;
                    text-align: justify;
                    font-weight: 400;

                    &:not(:first-child) {
                        margin-top: 10px; // 1.075269vh
                    }
                }
            }
        }
    }

    .adv-form {
        //height: 618px;
        background: #F8F9FB;

        .container {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h2 {
                font-size: 30px;
                height: 33px;
                line-height: 33px;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
                margin-top: 19.892473vh; // 185px;
            }

            h1 {
                margin-top: 8px;
                height: 42px;
                font-size: 30px;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .swiper {
                overflow: hidden;
                position: relative;
                margin: 0 133px;
            }

            .phone-wrap {
                margin: 50px 164px 40px;
                display: flex;
                justify-content: space-between;

                .phone {
                    width: 178px;

                    .image {
                        width: 178px;
                        height: 351px;
                    }

                    p {
                        margin-top: 20px;
                        font-size: 18px;
                        color: #333333;
                        text-align: center;
                        font-weight: 500;
                        height: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    .partner {
        padding-top: 26.021505vh; // 242px
        justify-content: flex-start;

        .container {
            h2 {
                font-size: 30px;
                height: 33px;
                line-height: 33px;
                color: #E0E3E7;
                text-align: center;
                font-weight: 700;
            }

            h1 {
                margin-top: 8px;
                height: 42px;
                font-size: 30px;
                color: #333333;
                text-align: center;
                font-weight: 600;
            }

            .partner-wrap {
                margin: 56.51px 200px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .partner-logo {
                    width: 162.51px;
                    height: 55.59px;
                }

                &:first-child {
                    margin-top: 80px;
                }
            }
        }
    }

    footer {
        height: 240px;
        background: url("../assets/images/bg_2.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        .footer-logo {
            width: 300px;
            height: 85px;
            background: url("../assets/images/footer-logo.png") no-repeat;
            background-size: 100%;
            margin: 78px 56px 0 100px;
        }

        .qrcode {
            width: 123px;
            height: 123px;
            background-color: #fff;
            margin-top: 58px;
        }

        .company {
            margin: 66px 0 0 36.8px;

            p {
                height: 20px;
                font-size: 14px;
                color: #FEFFFF;
                letter-spacing: 0;
                font-weight: 400;

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }
    }

    ::v-deep .swiper-button-next:after, ::v-deep .swiper-button-prev:after {
        font-size: 80px;
        color: #dfe2e8;
    }
}

.fp-viewing-footer #swiper-tips {
    display: none !important;
}
</style>
